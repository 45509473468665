exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-auth-callback-tsx": () => import("./../../../src/pages/auth-callback.tsx" /* webpackChunkName: "component---src-pages-auth-callback-tsx" */),
  "component---src-pages-confirm-tsx": () => import("./../../../src/pages/confirm.tsx" /* webpackChunkName: "component---src-pages-confirm-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-login-v-2-tsx": () => import("./../../../src/pages/login-v2.tsx" /* webpackChunkName: "component---src-pages-login-v-2-tsx" */),
  "component---src-pages-perform-tsx": () => import("./../../../src/pages/perform.tsx" /* webpackChunkName: "component---src-pages-perform-tsx" */),
  "component---src-pages-unavailable-tsx": () => import("./../../../src/pages/unavailable.tsx" /* webpackChunkName: "component---src-pages-unavailable-tsx" */)
}

